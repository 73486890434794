<template>
    <h2 class="article-subtitle" v-html="content.text"></h2>
</template>
<script>
export default {
    props: ["content"]
}
</script>
<style lang="scss">
@import '@/assets/sass/_responsiveness.scss';

.article-subtitle {
  
    font-size: 1.25em;
    font-weight:bold;
    margin:0.2em 0;


}
</style>