<template>
    <div class="bodyText" v-html="content.text"></div>
</template>
<script>
export default {
    props: ["content"]
}
</script>
<style lang="scss">

.bodyText {
    margin: 1.5em 0;
    font-size: 1em;
}
</style>