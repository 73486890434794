import { createRouter, createWebHashHistory } from 'vue-router';
import Page from '../components/core/page/Page.vue';
import store from '../store/store';
import config from "@/config";

const routes = [
    { path: config.includeLanguageCodeInUrl ? "/:lang([a-z]{2})?/:id*" :  "/:id*", component: Page }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// Router navigation guard
router.beforeEach((to, from, next) => {
    const storedLanguage = getCurrentLanguage();
    const routeLanguage = to.params.lang;

  
    // If there is no language in the route or if the route language is different from the stored language, redirect
    if (config.includeLanguageCodeInUrl) {
        if(!routeLanguage){ 
            next({ path: `/${storedLanguage}${to.fullPath}`, replace: true });
            return;
        } else if(routeLanguage !== storedLanguage) {
            // If the route changes to another language, simply reload the site to let DataDecorator handle changing the language
            location.reload()
        }
    }

    // Allow the navigation
    if (store.getters.canNavigate) {
        if(!hasQueryParameters(to) && hasQueryParameters(from)){
            store.commit('setQueryParameters', from.query)
            next({ ...to, query: from.query });
        } else {
            store.commit('setQueryParameters', to.query)
            next()
        }

        // Send events to Tracking API
        app.api.Tracking.navigation("navigation", to.fullPath, "");
        app.api.AudioManager.checkIfAudio(to.fullPath);
    } else {
        // Cancel the navigation
        // You can redirect to a specific route or show a loading screen until the navigation is allowed
        next(false)
    }
})

// Look up if route has any query parameters
function hasQueryParameters(route) {
    return !!Object.keys(route.query).length
}

// Get saved langugage code from localstorage
function getCurrentLanguage() {
    const storedLanguage = JSON.parse(localStorage.getItem(config.name))["lang"];
    return storedLanguage || ''; // Return empty string if stored language is null or undefined
}

export default router
