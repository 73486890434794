<template>
    <AspectContainer>
        <Idle v-if="hasIdle"></Idle>
        <UiLayer></UiLayer>
        <TransitionVideos v-if="hasTransitionVideoes"></TransitionVideos>
        <router-view></router-view>
        <PageStaticBackground></PageStaticBackground>
    </AspectContainer>
</template>


<script>
import config from '@/config';
import AspectContainer from '../core/AspectContainer.vue';
import Idle from '../core/Idle.vue';
import UiLayer from "../core/UiLayer.vue";
import TransitionVideos from "../core/TransitionVideos.vue";
import PageStaticBackground from "../core/page/PageStaticBackground.vue";

export default {
    components: {
        AspectContainer,
        Idle,
        UiLayer,
        TransitionVideos,
        PageStaticBackground
    },
    beforeMount() {
        document.title = this.pageTitle;
    },
    computed: {
        pageTitle() {
            this.$route;
            var breadcrumbs = app.api.PageManager.getBreadcrumbs().filter(crumb => crumb !== undefined)
            return (breadcrumbs.length>1) ? `${config.name} - ${breadcrumbs[breadcrumbs.length-1].title}` : config.name;
        },
        hasIdle() {
            if(!app.api.PageManager.getCustomComponentFromType("idle")) {
                return false
            } else {
                return true
            }
        },
        hasTransitionVideoes() {
            if(!app.api.PageManager.getCustomComponentFromType("videoTransitions")) {
                return false
            } else {
                return true
            }
        }
    },
    watch: {
        pageTitle() {
            document.title = this.pageTitle;
        }
    }
}
</script>