<template>
    <router-link :to="intenalLinkRoute">
        {{content.text}}
    </router-link>
</template>
<script>
export default {
    props: ["content"],
    computed: {
        intenalLinkRoute() {
            return app.api.PageManager.getRouteFromIDs(this.content.sceneID, this.content.contentID)
        }
    }
}
</script>