<template>
    <div class="articleMedia" :class="{ 'isVideo': isVideo, 'articleMedia--fullscreen': fullscreen }">
        <img :src="getSrc(content.mediaFile.src)" v-if="!isVideo"  @load="handleMediaLoaded">
        <video ref="video" :src="getSrc(content.mediaFile.src)" v-if="isVideo" :controls="fullscreen"
            @click="toggleVideoPlay()" @playing="isVideoPaused = false" @pause="isVideoPaused = true"
            type="video/mp4" @canplay="handleMediaLoaded"></video>
        <button class="video-play" v-if="isVideo && isVideoPaused && !fullscreen">
            <img src="@/assets/img/ui/play.svg">
        </button>

        <button class="fullscreen-toggle" v-if="content.allowFullScreen" @click="toggleFullScreen()">
            <img v-show="!fullscreen" src="@/assets/img/ui/fullscreen.svg">
            <img v-show="fullscreen" src="@/assets/img/ui/close.svg">
        </button>

    </div>

    <div v-show="fullscreen" class="fullscreen-shade"></div>
</template>

<script>
export default {
    props: ["content"],
    emits: ['loaded'],
    data() {
        return {
            fullscreen: false,
            isVideoPaused: true,
            // toggleUi: true,
        }
    },
    methods: {
        openContent() {
            if (this.content.link)
                app.api.PageManager.openExplicitContentComponentFromID(this.content.link)
        },
        getSrc(src) {
            return app.api.Utils.getMediaPath(src);
        },
        toggleFullScreen() {
            this.fullscreen = !this.fullscreen;
            // ui layer no!
           
        },
        toggleVideoPlay() {
            if (this.isVideoPaused) {
                this.$refs.video.play();
            } else {
                this.$refs.video.pause();
            }
        },
        handleMediaLoaded() {
            this.$emit('loaded');
        }
    },
    computed: {
        computedSrc() {
            if (this.content.image)
                return app.api.Utils.getMediaPath(this.content.image.src)
        },
        isVideo (){
            return app.api.Utils.getMediaType(this.content.mediaFile.src) == 'video';
        }
    }
}
</script>
<style lang="scss">
@import '@/assets/sass/_responsiveness.scss';


.articleMedia {
    position: relative;
    width: 100%;
    margin: 1.5em 0 2em;
    display: flex;
    justify-content: center;
    align-items: center;

    &>img,
    video {
        width: 100%;
        display: block;
       cursor: pointer;
    }

    &>img {
        pointer-events: none;
    }

    button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        box-shadow: var(--shadow-medium);
        z-index: 2;
        transition: transform var(--tk-easeinout) 0.15s;
        background-size: 100%;
        background-color: var(--petrol);

        &.fullscreen-toggle {
            right: 1em;
            top: 1em;
            width: 36px;
            height: 36px;

           

            &:hover {
                transform: scale(1.1);
            }
        }

        &.video-play {
            // fake button
            pointer-events: none;
            width: 45px;
            height: 45px;
            border: 2px solid #fff;

         
            &::after {}
        }

        &>img {
            width: 50%
        }
    }

    &--fullscreen {
        position: fixed;
        // background: rgba(0, 0, 0, 0.5);
        // backdrop-filter: blur(5px);
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        padding: 45px;
        z-index: 1000;
        margin: 0;

        &.isVideo {
            padding: 0;
        }

        &>img {
            height: 100%;
            object-fit: contain;
        }

        &>video {
            height: 100%;
        }
    }

    &:hover {
        button.video-play {
            transform: scale(1.1);
        }
    }



}

.fullscreen-shade {
    position: fixed;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        height: 100%;
        width:2160px;

        left: -120px;
        top: 0;
        z-index: 999;
}
</style>