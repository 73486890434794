<template>
    <transition name="HotspotEntrance">
        <router-link class="Hotspot btnfx" v-show="ready" :to="hotspot.route"
            :class="[getHotspotMutatorClasses, getHotspotContentClass, getHotspotTypeClass]" :style="[getPosition]"
            @click="hotspotPressed">
            <div class="Hotspot__button btnfx__clicklight btnfx__hoverhalo"
                :style="[getAlternateHotspotIcon]"></div>
            <span v-html="getHotspotTitle" :style="[getDistance]"></span>
            <div class="line" :style="[getArmLenth]"></div>
        </router-link>
    </transition>
</template>

<script>

export default {
    props: ['hotspot'],
    data() {
        return {
            ready: false
        }
    },
    mounted() {
        this.renderHotspotCascade();
    },
    methods: {
        renderHotspotCascade() {
            let delay = 0;
            delay = (this.hotspot.index * 200) + 400;

            setTimeout(() => {
                this.ready = true;
            }, delay);
        },
        getRandomAnimationDelay(min, max) {
            //return a random value for randomly timed animations
            return Math.random() * (max - min) + min;
        },
        hotspotPressed() {
            this.$emit('hotspotPressed', this.hotspot)
        }
    },
    computed: {
        getPosition() {
            return {
                left: this.hotspot.data.positionData.xPosition + 'px',
                top: this.hotspot.data.positionData.yPosition + 'px'
            }
        },
        getDistance() {
            var distance = (this.hotspot.data.armLength > 0) ? this.hotspot.data.armLength + 15 : 30;
            return {
                left: (!this.hotspot.data.flipped) ? distance + 'px' : 'auto',
                right: (this.hotspot.data.flipped) ? distance + 'px' : 'auto'
            }
        },
        getArmLenth() {
            var armLength = (this.hotspot.data.armLength > 0) ? this.hotspot.data.armLength : 15;
            return {
                width: armLength + 'px',
            }
        },
        getHotspotTitle() {
            return this.hotspot.data.alternateTitle && this.hotspot.data.alternateTitle !== '' ? this.hotspot.data.alternateTitle : this.hotspot.data.title;
        },
        getHotspotTypeClass() {
            let hotspotPrefix = "Hotspot--"

            switch (this.hotspot.data.hotspotType) {
                case "No Label":
                    return `${hotspotPrefix}NoLabel`

                // more types can be added here through the backend hotspotType data value.

                default:
                    return
            }
        },
        getHotspotMutatorClasses() {
            return {
                'Hotspot--flipped': this.hotspot.data.flipped,
                'Hotspot--hidden': this.hotspot.data.hidden,
                'Hotspot--customicon': this.hotspot.data.alternateHotspotIcon !== null
            }
        },
        getHotspotContentClass() {
            if (this.hotspot.componentData) {
                return `Hotspot--${this.hotspot.componentData.type}`
            } else {
                return
            }
        },
        getAlternateHotspotIcon() {
            if (this.hotspot.data.alternateHotspotIcon !== null) {
                return {
                    'background-image': `url(${app.api.Utils.getMediaPath(this.hotspot.data.alternateHotspotIcon.src)})`
                };
            }
        }
    }
}
</script>


<style lang="scss">
$hotspot-height: 30px;

.Hotspot {
    display: flex;
    // // center
    margin-top: calc(0px - $hotspot-height / 2);
    margin-left: calc(0px - $hotspot-height / 2);
    align-items: center;

    .Hotspot__button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: $hotspot-height;
        width: $hotspot-height;
        background-color: #fff;
        background-image: url(@/assets/img/ui/plus.svg);
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        border: 6px var(--petrol) solid;
    }

    span {
        position: absolute;
        display: flex;
        align-items: center;
        white-space: pre-wrap;
        font-weight: normal;
        color: var(--petrol);
        font-size: 16px;
        text-transform: uppercase;
        height: $hotspot-height;
        padding: 0 22px;
        background: var(--main-color);
        z-index: -1;
        border-radius: 24px;


        white-space: nowrap;
    }

    .line {
        position: absolute;
        height: 2px;
        background-color: #fff;
        left: 15px;
        z-index: 1;
    }


    &--flipped {

        .line {
            right: 15px;
            left: auto;
        }
    }

    // Styles for specifc components that hotspot can open
    &--fullscreenVideo,
    &--fullscreenIframePlayer {
        .Hotspot__button {
            background-image: url(@/assets/img/ui/play.svg);
        }
    }

    &--externalLink {
        .Hotspot__button {
            background-image: url(@/assets/img/ui/globe.svg);
        }
    }

    &--mail {
        .Hotspot__button {
            background-image: url(@/assets/img/ui/mail.svg);
        }
    }

    // Styles for specific hotspot types defined by getHotspotTypeClass()
    &--NoLabel {
        span {
            display: none;
        }
    }

    &--MediaControl {
        &.paused {
            .Hotspot__button {
                background-image: url(@/assets/img/ui/play.svg);
            }



        }

        &.playing {
            .Hotspot__button {
                background-image: url(@/assets/img/ui/pause.svg);
            }

            span {
                display: none;
            }
        }
    }

    &:hover {
        .defaultHotspot__button {
            transform: rotate(90deg);
        }

        .defaultHotspot__label {
            background: rgba(255, 255, 255, 1);
        }
    }
}

/* entrance animation */
.HotspotEntrance-enter-active {
    transition: all 1s var(--tk-easeinout);
}

.HotspotEntrance-leave-active {
    transition: all 0.3s var(--tk-easeinout);
}

.HotspotEntrance-enter-from {
    transform: translateY(-300%);
    opacity: 0;
}

.HotspotEntrance-leave-to {
    opacity: 0;
}

/* default*/
@keyframes hotspotLoopPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
        transform: scale(1.3);
    }

    7% {
        box-shadow: 0 0 0 220px rgba(255, 255, 255, 0);
        transform: scale(1.05);
    }

    10% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        transform: scale(1);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        transform: scale(1);
    }
}
</style>