<template>
  <transition name="toggleArticleFull" enter>

    <article class="ArticleFull" :class="{ active: ready }" v-if="ready">

      <h2 class="ArticleFull__title">{{ properties.title }}</h2>


      <div class="ArticleFull__scrollarea" ref="scroll_container" @mousewheel="scrollX"
        :class="{ navOpen: navigationOpen }" @DOMMouseScroll="scrollX">


        <!-- standard columned sections -->
        <div class="ArticleFull__columned">

          <section v-for="section, i in children" :key="i" :data-page-name="section.name"
            :class="{ 'force-to-next': i < children.length - 1 && children[i + 1].data.startInNewColumn }">
            <!-- section title (anchor linked) -->
            <h3 class="section__title">{{ section.name }}</h3>
            <!-- section sub components -->
            <component :is="getContentComponent(content)" v-for="(content, j) in section.data.content"
              :key="content.nsAlias + '_' + j" :content="content" @loaded="setupSubmenu()"></component>
          </section>

          <!-- blank section for helping scroll to last -->

          <section v-if="children.length>1" class="blank force-to-next">&nbsp;</section>
          <section v-if="children.length>1" class="blank force-to-next">&nbsp;</section>
          <!-- <section v-if="children.length>1" class="blank force-to-next">&nbsp;</section> -->

        </div>

      </div>

      <div class="ArticleFull__menu" :class="{ hidden: !navigationOpen }">

        <div class="nav-content">
          <img src="@/assets/img/ui/logo.svg">
          <div class="content-links">
            <a v-for="link in scrollTos" :class="{ active: link.active }" @click="scrollTo(link)">
              <!-- <span></span> -->
              {{ link.title }}
            </a>
          </div>
        </div>
      </div>

      <a @click="closeComponent()" class="ArticleFull__close">
        <span class="icon"><img src="@/assets/img/ui/close.svg"></span>
        <span>CLOSE</span>
      </a>

    </article>
  </transition>
</template>


<script>
import { markRaw } from 'vue';
// GSAP
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);


export default {
  props: [
    "properties",
    "pageData",
    "children",
    "slug"
  ],
  data() {
    return {
      ready: false,
      contentComponents: {},

      scrollTos: [],
      navigationOpen: true
    }
  },
  created() {
    this.importArticleComponents();
  },
  mounted() {
    this.ready = true;

    setTimeout(() => {
      this.setupSubmenu();
    }, 10);


  },
  methods: {
    importArticleComponents() {
      // Use require.context to dynamically import components from the ./hotspot-components subfolder
      const componentContext = require.context('./article-components/', true, /\.vue$/);

      // Iterate over each component path and extract the component name
      componentContext.keys().forEach((componentPath) => {
        const componentName = componentPath.replace(/^.\/(.*)\.\w+$/, '$1');

        // Import the component and mark it as non-reactive using markRaw
        this.contentComponents[componentName] = markRaw(componentContext(componentPath).default);
      });
    },
    getContentComponent(content) {
      // Retrieve the corresponding component name from the "type" supplied by the Umbraco model.
      // If the component is not found, use the 'defaultHotspot' component
      const componentName = content.nsAlias;
      const component = this.contentComponents[componentName];

      if (!component) {
        console.log('%c No component found for type: ' + componentName, 'color: red;');
      }

      return component;
    },

    closeComponent() {
      this.ready = false;
      this.$emit("closeComponent", this.slug);
    },

    setupSubmenu() {

      // cleanup befor redoing
      this.removeScrollListener();
      this.scrollTos = [];

      var sectionTitles = document.getElementsByClassName("section__title");

      Array.from(sectionTitles).forEach((titleEl, i) => {
        this.scrollTos.push({
          title: titleEl.innerHTML,
          scrollX: titleEl.offsetLeft,
          active: (i == 0) ? true : false
        })

      });

      // update menu on scroll
      this.addScollListener();


    },
    scrollX(e) {

      switch (app.api.Utils.getBrowser()) {
        case "Mozilla Firefox":
          this.$refs['scroll_container'].scrollLeft += 30 * e.detail;
          break;
        default:
          this.$refs['scroll_container'].scrollLeft += e.deltaY;
          break;

      }

    },
    scrollTo(clickedSection) {

      this.scrollTos.forEach((section, i) => {
        section.active = (clickedSection == section) ? true : false;
      });

      var scrollContainer = this.$refs['scroll_container'];

      // stop scroll listener
      this.removeScrollListener();

      // animate
      var maxScroll = scrollContainer.scrollWidth - scrollContainer.clientWidth;
      var scrollToBounded = Math.min(maxScroll, clickedSection.scrollX);
      gsap.to(scrollContainer, {
        duration: 0.5, ease: "expo.InOut", scrollTo: {
          y: 0, x: scrollToBounded
        }, onComplete: this.addScollListener
      });


    },

    addScollListener() {
      this.$refs['scroll_container'].addEventListener('scroll', this.updateActiveMenuLink);
    },

    removeScrollListener() {
      this.$refs['scroll_container'].removeEventListener('scroll', this.updateActiveMenuLink);
    },

    updateActiveMenuLink() {

      const activeOnPixelsBefore = 300;
      var currentX = this.$refs['scroll_container'].scrollLeft + activeOnPixelsBefore;

      this.scrollTos.forEach((section, i) => {
        var nextSectionX = (i < this.scrollTos.length - 1) ? this.scrollTos[i + 1].scrollX : 10000000;

        if (section.scrollX <= currentX && currentX < nextSectionX) {
          section.active = true;
        } else {
          section.active = false;
        }

        //console.log(section.scrollX + "<" + currentX + "<" + nextSectionX);

      });


    }

  },

  unmounted() {
    // app.api.Utils.showUiLayer();
  },

  computed: {
  }
};
</script>


<style lang="scss" scoped>
* {
  --column-width: 540px;
  --column-gap: 80px;
}

.ArticleFull {
  position: absolute;
  top: 50px;
  right: 0px;
  left: 200px;
  color: var(--ocean);
  bottom: 50px;
  background-color: #fff;
  overflow: hidden;

  &__title {
    position: absolute;
    left: 400px;
    top: 60px;
    font-size: 64px;
  }


  &__close {
    position: absolute;
    left: 40px;
    bottom: 25px;
    display: flex;
    align-items: center;
    color: var(--main-color);
    cursor: pointer;
    z-index: 20;
    gap: 20px;

    .icon {

      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #fff;
      border-radius: 50%;

      img {
        width: 60%;
      }
    }

  }


  &__scrollarea {
    position: absolute;
    right: 80px;
    left: 400px;
    top: 200px;
    bottom: 33px;
    text-align: left;
    padding-right: 150px;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
  }

  &__columned {
    height: 100%;
    width: fit-content;
    column-width: var(--column-width);
    width: var(--column-width);
    column-gap: var(--column-gap);
    column-count: auto;
    column-fill: auto;
    height: calc(100% - 50px); // 50px margin

    section {

      margin-bottom: 60px;

      &.force-to-next {
        margin-bottom: 1000px
      }

      &.blank {
        width:calc( var(--column-width) + var(--column-gap));
      }

      .section__title {
        font-size: 30px;
        font-weight: bold;
        margin: 0.1em 0 0.4em;
      }
    }
  }

  &__menu {
    position: absolute;
    width: 320px;
    left: 0;
    height: 100%;
    background-color: var(--petrol);
    z-index: 10;

    padding: 40px;
    text-align: left;
    color: #fff;
    transition: transform 0.3s;



    .nav-content {
      margin-top: 42px;

      img {
        width: 80%;
        margin-bottom: 50px;

      }

      .content-links {

        a {
          font-size: 20px;
          display: flex;
          line-height: 30px;
          cursor: pointer;
          align-items: center;
          color: var(--ocean);
          font-weight: 200;
          margin-bottom:1em;


          span {
            width: 18px;
            height: 2px;
            margin-right: 12px;
            background-color: #fff;
            opacity: 0.1;
            transition: opacity 0.5s;
          }

          &.active {
            font-weight: bold;
            color: #fff;

            span {
              opacity: 1;
            }

          }


        }

      }
    }

  }



}

.toggleArticleFull-enter-active,
.toggleArticleFull-leave-active {
  transition: all .5s cubic-bezier(0.770, 0.000, 0.175, 1.000);
}

.toggleArticleFull-enter-from,
.toggleArticleFull-leave-to {
  opacity: 0;
  transform: translateX(50%);
}
</style>