export default class Tracking {
    constructor(app) {
        this.app = app;
    }


    // Navigation with hooks in the $Router
    navigation(action, property, payload) {
        // expected format:
        // {Action: "click", Property: "https://www.site.dk/url", Payload: "Any string-data associated with the action"}

        let trackingObject = {
            Action: action,
            Property: property,
            ...(payload !== undefined && { Payload: payload })
        }
        
        this.CreateLog(trackingObject)
    }
    
    // Explicitly called events with js (buttonclicks etc.)
    event(action, property, payload) {
        let trackingObject = {
            Action: action,
            Property: property,
            ...(payload !== undefined && { Payload: payload })
        }
            
        this.CreateLog(trackingObject)
    }
    
    CreateLog(data) {
        console.log(`Tracking => \nAction: ${data.Action}\nProperty: ${data.Property}`);
    }
}