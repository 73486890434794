<template>
    <div class="table">
        <div class="table__row" v-for="row in content.rows" :class="{ '--header': row.isHeaderRow }">
            <div class="table__col left" v-html="row.leftColumn"></div>
            <div class="table__col" v-html="row.rightColumn"></div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["content"]
}
</script>
<style lang="scss">
@import '@/assets/sass/_responsiveness.scss';

.table {

    margin-bottom: 2em;
    display: inline-flex;
    flex-direction: column;
    width: 100%;

    &__row {
        display: grid;
        grid-template-columns: 2fr 2fr;
        border-bottom: 1px solid var(--blue-grey-medium);

        &.--header {
            font-weight: bold;
            background-color: var(--blue-grey-light);
       
        }

        &:last-of-type {
            border-bottom: none;
        }
    }

    &__col {

        display: flex;
        align-items: center;
        padding: 0.5em 1em;

        &>p {
            margin: 0
        }

        &.left {
            border-right: 1px solid var(--blue-grey-medium);
            ;
        }

    }


}
</style>