import config from "@/config";
import store from '@/store/store'

export default class Utils {
    constructor(app) {
        this.app = app;
    }

    // Resolve path to media asset
    getMediaPath(url = '') {
        const mediaFolder = config.mediaFolder;

        if (/http/.test(mediaFolder)) {
            const [protocol, host] = config.mediaFolder.split('//');
            return `${protocol}//${host}/${url.replace(/(.*)\/media\//g, '').replace(/\/{1,}/g, '/')}`;
        } else {
            return `${mediaFolder}/${url.replace(/(.*)\/media\//g, '').replace(/\/{1,}/g, '/')}`;
        }
    }

    // Determine the type of media file
    getMediaType(src) {
        // Regular expressions to match image, video, and audio file extensions
        const imageExtensions = /\.(jpe?g|png|gif|bmp|webp|svg)$/i;
        const videoExtensions = /\.(mp4|m4v|ogg|webm|mkv|flv|avi|wmv|mov)$/i;
        const audioExtensions = /\.(mp3|wav|ogg|aac)$/i;

        // Determine the file type based on the src string
        let fileType = 'unknown';
        if (src.match(imageExtensions)) {
            fileType = 'image';
        } else if (src.match(videoExtensions)) {
            fileType = 'video';
        } else if (src.match(audioExtensions)) {
            fileType = 'audio';
        }

        return fileType;
    }

    // Sanitize slugs for a clean URL
    sanitizeString(slug = '') {
        var s = slug
            .toLowerCase()
            .replace(/[^\w ]+/g, '')
            .replace(/ +/g, '-')

        return encodeURI(s).replace('%E2%80%93', '-')
    }

    // Set language, save it in localstorage and reload the app
    setLanguage(languageCode = 'en') {
        this.app.api.LocalDataStore.set("lang", languageCode);

        // Inject the language code directly into the current url
        if(config.includeLanguageCodeInUrl) {
            let hasWebHashHistory = window.location.href.includes("/#/") ? "/#/" : "";
            let outputfolder = window.location.href.split('/#/')[0]
            let newURL = `${outputfolder}${hasWebHashHistory}${languageCode}${app.api.PageManager.getCurrentRoute().slice(3)}`;
            history.pushState({}, null, newURL);
        }

        location.reload();
    }

    // Get the currently used language from local storage
    getCurrentLanguage() {
        return this.app.api.LocalDataStore.get("lang");
    }

    /* FULLSCREEN */
    fullScreenEnable() {
        const element = document.body;

        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    }

    fullScreenDisable() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }

    isFullscreen() {
        return window.innerHeight == screen.height;
    }

    toggleFullscreen() {
        if (this.isFullscreen()) {
            this.fullScreenDisable();
            store.commit('toggleFullscreen', false);
        } else {
            this.fullScreenEnable();
            store.commit('toggleFullscreen', true);
        }
    }


    getBrowser() {
        // Get the user agent string
        const userAgent = navigator.userAgent;

        // Check for Microsoft Edge
        if (userAgent.indexOf("Edge") > -1) {
            return "Microsoft Edge";
        }

        // Check for Opera
        if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
            return "Opera";
        }

        // Check for Chrome
        if (userAgent.indexOf("Chrome") > -1) {
            return "Google Chrome";
        }

        // Check for Safari
        if (userAgent.indexOf("Safari") > -1) {
            return "Safari";
        }

        // Check for Firefox
        if (userAgent.indexOf("Firefox") > -1) {
            return "Mozilla Firefox";
        }

        // Default to other
        return "Unknown";
    }

    // Preload video
    async preloadVideo(src) {
        let realSrc = this.getMediaPath(src);


        // Fetch the video using the specified src
        const response = await fetch(realSrc);
            
        // Check if the fetch was successful
        if (!response.ok) {
            throw new Error(`Error preloading video: ${response.statusText}`);
        }

        // Check the content type of the response
        const contentType = response.headers.get('content-type');
        if (!contentType.includes('video/mp4') && getBrowser() == "Safari") {
            console.log(`Invalid video content type or format for current browser Safari. (content type: ${contentType})`, "\n src: " + src);
            return Promise.resolve()
        }

        // Get the video data from the response
        const videoBlob = await response.blob();

        // Create an object URL from the video data
        const videoUrl = URL.createObjectURL(videoBlob);

        // Create a new video element
        const video = document.createElement('video');
        video.preload = 'auto';
        video.setAttribute('playsinline', '');

        // Set the src of the video to the object URL
        video.src = videoUrl;
        video.load();

        // Return a promise that resolves once the video has finished preloading
        return new Promise((resolve, reject) => {
            video.addEventListener('canplay', () => {
                resolve();
            });
            video.addEventListener('loadedmetadata', () => {
                resolve();
            });
            video.addEventListener('error', () => {
                console.log("Video preload failed! skipping preload for => \n", video)
                resolve();
            });
        });
    }

    async preloadImage(src) {
        // create Image element and append it to the dom for preload
        const image = new Image();
        image.src = this.getMediaPath(src);
        image.onerror = function (error) {
            console.error(`Error preloading image: ${error}`);
        };
    }

    // Preload audio
    async preloadAudio(src) {
        let realSrc = this.getMediaPath(src);

        // Fetch the audio using the specified src
        const response = await fetch(realSrc);

        // Check if the fetch was successful
        if (!response.ok) {
            throw new Error(`Error preloading audio: ${response.statusText}`);
        }

        // Get the audio data from the response
        const audioBlob = await response.blob();

        // Create an object URL from the audio data
        const audioUrl = URL.createObjectURL(audioBlob);

        // Create a new audio element
        const audio = new Audio();

        // Set the src of the audio to the object URL
        audio.src = audioUrl;

        // Return a promise that resolves once the audio has finished preloading
        return new Promise((resolve, reject) => {
            audio.addEventListener('canplay', () => {
                resolve();
            });
            audio.addEventListener('error', () => {
                reject(new Error('Audio preload failed.'));
            });
            audio.load();
        });
    }

    // Get array of video transitions stored in the vuex store
    getVideoTransitions() {
        return store.getters.getVideoTransitions;
    }

    // Check if a video transition will be played between two routes
    checkIfVideoTransition(from, to) {
        let transitions = this.getVideoTransitions();
        let hasVideoTransition = false;

        for (let i = 0; i < transitions.length; i++) {
            if (transitions[i].from == from && transitions[i].to == to) {
                hasVideoTransition =  true
            }
        }

        return hasVideoTransition;
    }
}