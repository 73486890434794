<template>
    <router-link :to="homeURL" id="logo">
        <img src="@/assets/img/ui/logo.svg">
    </router-link>
</template>
<script>
export default {
    computed: {
        homeURL() {
            return app.api.PageManager.getRootPage().route
        }
    }
}
</script>
<style lang="scss" scoped>
    #logo {
        position: absolute;
        width: 250px;
        top: 100px;
        left: 92px;
        z-index: 2;
        cursor: pointer;
        opacity: 0;
        // transform:translateX(-100%);
        transition: all 0.4s;
        
        &>img {
            width: 100%;
        }

        &.visible {
            transition: all 0.4s  0.2s;
            opacity: 1;
            transform:translateX(0);
            pointer-events: all;
        }
    }
</style>