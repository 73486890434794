<template>
    <nav id="Navigation">


        <a v-for="link, i in navigationLinks" @click="changePage(link.sceneID)" :key="link.sceneID"
            @mouseover="pauseRevealAnimation" @mouseout="startRevealAnimation"
            :class="{ 'active': currentSceneID == link.sceneID, 'reveal': revealAnimation == i && onHomePage }">
            <div class="letterball">{{ link.letter }}</div>
            <div class="line"></div>
            <span class="titlebox">{{ link.title }}</span>
            <span class="title">{{ link.title }}</span>
        </a>


    </nav>
</template>
<script>
export default {

    data() {
        return {
            navigationLinks: [],
            // transitionDuration: 1000,
            // pageTransitioning: false,
            currentSceneID: -1,
            revealAnimation: -1,
            intervalTimer: null
        };
    },

    mounted() {
        this.navigationLinks = app.api.PageManager.getCustomComponentFromType("navigation").data.links;

        // get current scene id
        let tempRoute = app.api.PageManager.getCurrentRoute().split(/[\/:]/).filter(el => el != '' || null);
        this.navigationLinks.forEach(link => {
            if (link.title.toLowerCase() == tempRoute[tempRoute.length - 1]) {
                this.currentSceneID = link.sceneID;
            }
        });

        this.startRevealAnimation();

    },
    methods: {

        changePage(sceneID) {
            app.api.PageManager.navigateToID(sceneID);
            this.currentSceneID = sceneID
        },
        pauseRevealAnimation() {
            clearInterval(this.intervalTimer);
            this.revealAnimation = -1;

        },
        startRevealAnimation() {
            clearInterval(this.intervalTimer);
            let _this = this;
            this.intervalTimer = setInterval(() => {
                _this.revealAnimation = (_this.revealAnimation + 1) % _this.navigationLinks.length;
            }, 2500);
        }
    },
    computed: {
        onHomePage() {
            this.$route;
            let isHomePage = app.api.PageManager.onHomePage();
            if (isHomePage) {
                this.currentSceneID = -1;
                this.revealAnimation = -1;
                this.startRevealAnimation();
            }
            return isHomePage;
        }
    }
}
</script>
<style lang="scss" scoped>
#Navigation {
    position: absolute;
    top: 950px;
    left: 92px;
    display: flex;
    gap: 27px;
    opacity: 0;
    transition: opacity 0.1s;

    &.visible {
        transition: all 0.4s 0.2s;
        opacity: 1;

        a {
            pointer-events: all;
        }
    }


    a {
        pointer-events: none;
        position: relative;
        color: #fff;
        display: flex;
        justify-content: center;
        cursor: pointer;

        .letterball {
            width: 51px;
            height: 51px;
            border-radius: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 3px solid #fff;
            font-weight: bold;
            font-size: 34px;
            transition: all 0.3s;
            overflow: hidden;
        }

        .line {
            position: absolute;
            height: 48px;
            width: 3px;
            background-color: #fff;
            top: 50px;
            transform: scaleY(0);
            transform-origin: 50% 0%;
            transition: transform 0.5s;
        }


        .title,
        .titlebox {
            position: absolute;
            height: 28px;
            border-radius: 14px;
            font-size: 16px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 70px;
            text-transform: uppercase;
        }

        .title {
            width: 0px;
            overflow: hidden;
            transition: width 0.3s;
        }

        .titlebox {
            color: transparent;
            padding: 0 15px;
            transform: scaleX(0);
            transition: transform 0.3s;
            border: 3px solid #fff;
            background-color: var(--petrol);
        }

        &.active {
            .letterball {
                background-color: #fff;
                color: var(--petrol);
            }
        }

        &.reveal,
        &:hover {
            .letterball {
                color: var(--petrol);
                background-color: #fff;
            }

            .line {
                transform: scaleY(1);
            }

            .title {
                width: 200px;
                transition: width 0.5s 0.5s;
            }

            .titlebox {
                transform: scaleX(1);
                transition: transform 0.5s 0.4s;
            }
        }

        &:hover {
            .letterball {
                color: #fff;
                background-color: #fff5;
            }
        }



    }


}
</style>