import config from "@/config"

export default class LocalDataStore {
    constructor(app) {
        this.app = app;

        this.data = this.initializeStorage()
    }

    initializeStorage() {
        // If the key for the app isn't in localStorage, create an object with a language key and set it to the app's language
        if (!localStorage.getItem(config.name)) {
            let initialData = { lang: config.defaultLanguage }
            localStorage.setItem(config.name, JSON.stringify(initialData))
            return initialData
        }
        // Otherwise, get the data from localStorage
        return JSON.parse(localStorage.getItem(config.name));
    }

    set(key, keyVal) {
        // Set the value of the given key in the data object to the given value, save the updated data to localStorage, and return the new value
        this.data[key] = keyVal
        this.save()
        return this.data[key]
    }

    get(key) {
        // If the key exists in localStorage, return its value. Otherwise, log an error and return false.
        if (this.has(key))
            return JSON.parse(localStorage.getItem(config.name))[key];
        console.log(`The specific key: "${key}" was not found in the local storage`)    
        return false;
    }

    has(key) {
        // Return true if the given key exists in localStorage
        var o = JSON.parse(localStorage.getItem(config.name)) || {};
        return (key in o);
    }

    remove(key, prop) {
        // If the key exists in the data object, remove it (or a property of it), save the updated data to localStorage, and return true.
        if (this.has(key)) {
            if (prop && prop in this.data[key]) {
                delete this.data[key][prop]
                this.save({ key: `${key}.${prop}`, action: 'remove' })
            } else {
                delete this.data[key]
                this.save({ key, action: 'remove' })
            }
            return true;
        }
        
        return false
    }

    list() {
        // Return an array of all the keys in the data object.
        return Object.keys(this.data);
    }

    save() {
        // Save the current state of the data object in the localStorage.
        localStorage.setItem(config.name, JSON.stringify(this.data));
    }
}