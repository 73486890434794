import { Howl } from 'howler';
import store from '../../store/store.js';

export default class AudioManager {
    constructor(app) {
        this.app = app;
        
        // Public variables (use getters)
        this.audioInstances = {};
        this.playlist = [];
        this.volume = 0.2;

        // Local variables
        this.scenes = app.api.PageManager.getAllScenes();
        this.tempVolume = null;
        this.currentlyPlayingAudioId = 0;
        this.currentlyPlayingindex = null;
        this.idCounter = 0;

        // initial check on first intializing the app
        this.checkIfAudio(app.api.PageManager.getCurrentRoute());

        // listener for other audio sources to mute AudioManager temporarily
        this.otherAudioListener();
    }


    /* -------------------- Getters for outside components -------------------- */
    
    getCurrentAudioInstances() {
        return this.audioInstances
    }
    
    getPlaylist() {
        return this.playlist
    }
    
    getVolume() {
        return this.volume
    }

    
    /* ---------------- Functions for use in external components ---------------- */

    // Next audio track in playlist
    next() {
        console.log(`Audio => \Next`);
        this.clearAllAudioInstances();
        
        if(this.playlist[this.currentlyPlayingindex + 1]) {
            this.startAudio(this.currentlyPlayingindex + 1);
        } else if (this.playlist.length > 0) {
            this.startAudio(0)
        } else {
            console.log(`Audio => \No audio in playlist`);
        }
    }
    
    // Previous audio track in playlist
    previous() {
        console.log(`Audio => \Previous`);
        this.clearAllAudioInstances();
        
        if(this.playlist[this.currentlyPlayingindex - 1]) {
            this.startAudio(this.currentlyPlayingindex - 1);
        } else if (this.playlist.length > 0) {
            this.startAudio(this.playlist.length - 1);
        } else {
            console.log(`Audio => \No audio in playlist`);
        }
    }

    // Reference an id in audioManager to play a specific audio clip once
    playSpecificAudio(id) {
        if (this.app.api.PageManager.getCustomComponentFromType('audioManager')) {
            var audio = this.app.api.PageManager.getCustomComponentFromType('audioManager').data.miscAudio.find(audio => audio.uniqueID == id);
            if (!audio) {
                console.log(`Audio => Tried to play audio: '${id}' \nCould not find uniqueID:'${id}' in audioManager.miscAudio`);
            }

            this.playAudioFromSrc(audio.audio.src);
        } else {
            console.log(`Audio => Tried to play audio: '${id}' \nNo audioManager in Global Data`);
        }
    }

    // Use Howler to play a specific audio src once
    playAudioFromSrc(src) {
        const audioId = 'audio_' + src;

        // Create and play a Howl instance (The actual audio object that will be played)
        const player = new Howl({
            src: this.app.api.Utils.getMediaPath(src),
            volume: this.volume,
            onend: () => {
                // Clear audio instance when finished
                this.clearAudioInstance(audioId);
            }
        });

        console.log(`Audio => Playing audio from src: '${src}'`);
        
        // Play the Howl audio
        player.play();
    }
    
    
    /* ------------------------ Internal AudioManager -------------------------- */

    startAudio(index) {
        // Clear audio from audioInstances not in the playlist
        this.clearAudioNotInPlaylist();

        // Check if audio to be played is already playing
        if (this.checkIfRelevantAudioPlaying())
            return;

            
        // Assign a unique audio ID
        this.currentlyPlayingAudioId = this.idCounter;
        const audioId = 'audio_' + this.currentlyPlayingAudioId;
        this.currentlyPlayingindex = index;

        console.log(`Audio => Playing: ${this.playlist[index].title}`);
        
        // Create and play a Howl instance (The actual audio object that will be played)
        const player = new Howl({
            src: this.playlist[this.currentlyPlayingindex].src,
            volume: this.volume,
            onend: () => {
                // Clear audio instance when finished
                this.clearAudioInstance(audioId);

                // Start playing the next audio in the playlist or loop back to the beginning
                this.next();
            }
        });

        // Store the Howl instance in audioInstances
        this.audioInstances[audioId] = player;
        
        // Increment the audio ID counter to make the stored audio instances unique.
        this.idCounter++;

        // Play the Howl audio
        player.play();
    }

    // Check if relevant audio is already playing
    // (instead of changing audio, just keep playing if it is in the playlist 
    // to avoid starting audio over every navigation).
    checkIfRelevantAudioPlaying() {
        let playing = false;

        for (const audioId in this.audioInstances) {
            if (this.audioInstances.hasOwnProperty(audioId)) {
                let src = this.audioInstances[audioId]._src;

                if (this.playlist.find(audio => audio.src === src)) {
                    playing = true;
                }
            }
        }

        return playing;
    }

    
    // Function to determine if audio should be played based on the current route
    checkIfAudio(route) {
        let scene = this.scenes.find(scene => scene.route == route.split(":")[0])

        if (!scene)
            return;

        // Scene specific audio
        if (scene.components.find(component => component.type === 'sceneAudio')) {
            this.adjustPlaylist(scene.components.find(component => component.type === "sceneAudio").properties.playlist);
        
        // Global fallback Audio
        } else if (this.app.api.PageManager.getCustomComponentFromType('audioManager')) {
            this.adjustPlaylist(this.app.api.PageManager.getCustomComponentFromType('audioManager').data.playlist);
            
        // Clear playlist if no audio should be played
        } else {
            this.clearPlaylist();
        }
    }


    // Adjust the playlist based on umbraco data from either scene backround- or global component
    adjustPlaylist(audioList) {
        this.cleanPlaylist(audioList);

        audioList.forEach(audio => {
            if (!this.playlist.find(playlistAudio => playlistAudio.src.includes(audio.audio.src)))
                this.addAudioToPlaylist(audio.audio);
        });

        this.shuffle(this.playlist);

        
        // Update currentlyPlayingindex to the relevant index of a shuffled playlist.
        let index = 0;
        for (const audioId in this.audioInstances) {
            if (this.audioInstances.hasOwnProperty(audioId)) {
                const audio = this.audioInstances[audioId];

                this.playlist.forEach((playlistAudio, i) => {
                    if (playlistAudio.src === audio._src) {
                        index = i;
                    }
                });
            }
        }

        this.currentlyPlayingindex = index;
        
        // Start playing audio from the beginning of the playlist
        this.startAudio(index);
    }
    
    // Clean playlist by removing entries not in the umbraco data from either scene background- or global component
    cleanPlaylist(audioList) {
        this.playlist = this.playlist.filter(obj1 => audioList.some(obj2 => obj2.audio.src === obj1.src));
    }

    // Simple method for handling pushing audio to the playlist in the correct data format
    addAudioToPlaylist(audio) {
        // Simplify data for playlist 
        var enrichedAudioObject = {
            title: audio.name ? audio.name : 'No audio title',
            src: this.app.api.Utils.getMediaPath(audio.src)
        };
        
        this.playlist.push(enrichedAudioObject);
    }

    // Shuffle playlist
    shuffle(array) {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex > 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }

        // Return a shuffled playlist
        return array;
    }

    

    // Clear the entire playlist and all audio instances
    clearPlaylist() {
        this.playlist = [];
        this.clearAllAudioInstances();
        console.log(`Audio => Stopping all playback`);
    }

    // Clear all audio instances
    clearAllAudioInstances() {
        for (const audioId in this.audioInstances) {
            if (this.audioInstances.hasOwnProperty(audioId)) {
                const audio = this.audioInstances[audioId];
                this.stopAndUnloadAudio(audio);

                // Remove the Howl instance from the object
                delete this.audioInstances[audioId];
            }
        }
    }

    // Clear active audio instances that are not in the playlist
    clearAudioNotInPlaylist() {
        for (const audioId in this.audioInstances) {
            if (this.audioInstances.hasOwnProperty(audioId)) {
                const audio = this.audioInstances[audioId];

                if (!this.playlist.find(playlistAudio => playlistAudio.src === audio._src)) {
                    this.stopAndUnloadAudio(audio);
                    
                    // Remove the Howl instance from the object
                    delete this.audioInstances[audioId];
                }
            }
        }
    }

    // Stop and unload the Howl instance
    stopAndUnloadAudio(audio) {
        audio.stop();
        audio.unload();
    }

    
    // Clear a specific audio instance by ID
    clearAudioInstance(id) {
        for (const audioId in this.audioInstances) {
            if (this.audioInstances.hasOwnProperty(audioId)) {
                if (id == audioId) {
                    const audio = this.audioInstances[audioId];
                    this.stopAndUnloadAudio(audio);
                    
                    // Remove the Howl instance from the object
                    delete this.audioInstances[audioId];
                }
            }
        }
    }


    /* ------------------VOLUME HANDLERS ------------------ */

    // Mute all audio instances
    mute() {
        // Store the old volume value
        this.tempVolume = this.volume;
        this.volume = 0;
        
        // Set volume for all active audioInstances
        for (const audioId in this.audioInstances) {
            if (this.audioInstances.hasOwnProperty(audioId)) {
                const audio = this.audioInstances[audioId];
                
                // Set volume to 0 for all audio instances
                this.fadeVolumeOut(audio);
            }
        }
        
        console.log(`Audio => Muted`);
    }
    
    // Unmute all audio instances
    unmute() {
        // Use the stored volume value.
        this.volume = this.tempVolume;
        
        // Set volume for all active audioInstances
        for (const audioId in this.audioInstances) {
            if (this.audioInstances.hasOwnProperty(audioId)) {
                const audio = this.audioInstances[audioId];
                
                // Set volume to the stored temporary volume variable for all audio instances
                this.fadeVolumeIn(audio, this.volume);
            }
        }

        // reset the temporary volume variable
        this.tempVolume = null;

        console.log(`Audio => Unmuted`);
    }

    // Set volume to a specific value between 0 and 1.
    // TODO: change volume on active audio instances
    setVolume(value) {
        this.volume = value;
        this.tempVolume = null;
    }

    // Fade effect for unmuting
    fadeVolumeIn(audio, volume) {
        const fadeDuration = 500;
        const fadeSteps = 25; // Number of steps in the fade
        const volumeStep = volume / fadeSteps;
        let currentVolume = 0;

        audio.volume(0); // Start with the volume at 0

        const fadeInterval = setInterval(() => {
            currentVolume += volumeStep;
            audio.volume(currentVolume);

            if (currentVolume >= volume) {
                audio.volume(volume); // Ensure it's exactly the original volume
                clearInterval(fadeInterval);
            }
        }, fadeDuration / fadeSteps);
    }

    // Fade effect for muting
    fadeVolumeOut(audio) {
        const initialVolume = audio.volume();
        const fadeDuration = 400;
        const fadeSteps = 25; // Number of steps in the fade

        const volumeStep = initialVolume / fadeSteps;
        let currentVolume = initialVolume;

        const fadeInterval = setInterval(() => {
            currentVolume -= volumeStep;
            audio.volume(currentVolume);

            if (currentVolume <= 0) {
                audio.volume(0); // Ensure it's exactly 0
                clearInterval(fadeInterval);
            }
        }, fadeDuration / fadeSteps);
    }


    /* ------------------------ OTHER ------------------------- */

    // listener for other audio sources to mute AudioManager temporarily
    otherAudioListener() {
        // Event listener to stop AudioManager from playing if another source of audio starts playing.
        window.addEventListener("muteAudio", e => {
            store.commit("mute");
        });

        // Event listener to start AudioManager playing if another source of audio stops playing.
        window.addEventListener("unmuteAudio", e => {
            store.commit("unmute");
        });
    }
}
