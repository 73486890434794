<template>
    <AppLoaderVue v-if="!ready"></AppLoaderVue>
    <AppContainer v-else></AppContainer>
</template>

<script>
import AppLoaderVue from './components/core/AppLoader.vue';
import AppContainer from './components/core/AppContainer.vue';

export default {
    components: {
        AppLoaderVue,
        AppContainer
    },
    created() {
        window.app.vm = this;
    },
    computed: {
        ready() {
            return this.$store.getters.ready;
        }
    },
};
</script>

<style lang="scss">
html,
body {
    height: 100vh;
    max-width: 100vw;
    scroll-behavior: smooth;
    background-color: var(--dark-grey);
    font-size: 18px;
    overflow: hidden;
    
    /* Disable zooming for all devices */
    touch-action: pan-x pan-y !important;
    user-scalable: none !important;
    -ms-touch-action: pan-x pan-y !important;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}

img, button, a {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

h2, h3, h4 {
    font-family: 'Futura';
    font-weight:800;
}

// Global Styling 
* {
    backface-visibility: hidden;
    touch-action: manipulation;

    // Scrollbar
    scrollbar-color: var(--dark-grey) var(--light-grey);
    scrollbar-width: thin;

    & *::-webkit-scrollbar {
        width: 8px;
    }

    & *::-webkit-scrollbar-track {
        background: var(--light-grey);
    }

    & *::-webkit-scrollbar-thumb {
        background: var(--dark-grey);
        height: 50%;
    }
}

p {
    display: block;
    margin-bottom: 1em;

    >a {
        display: inline;
        color: var(--secondary-color)
    }
}

strong, b {
    font-weight: 900;
}

em {
    font-style: italic;
}

ul, ol {
    list-style-position: outside;
    padding-left: 30px;
    margin-bottom: 24px;    

    li {
        margin-bottom: 4px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

ul {
    list-style-type: disc;
}

ol { 
    list-style-type: decimal;
}

button {
    cursor: pointer;

    &--disabled {
        cursor: default;
        pointer-events: none;
    }
}
</style>