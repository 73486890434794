<template>
    <transition name="toggleContentComponent">
        <article class="fullscreenVideo" v-if="ready" :class="{'fullscreenVideo--videopaused': isVideoPaused}">
            <video ref="video"
                v-if="properties.video.src"
                :autoplay="properties.autoplay"
                :controls="properties.controls"
                :mute="properties.mute"
                :loop="properties.loop"
                :poster="posterUrl"
                @playing="isVideoPaused = false"
                @pause="isVideoPaused = true;"
                type="video/mp4"
                playsinline
                webkit-playsinline
                crossorigin>
                <source :src="videoUrl">
            </video>

            <button class="fullscreenVideo__close" @click="closeComponent"></button>
        </article>
    </transition>
</template>

<script>


export default {
    props: ["properties", "pageData", "slug"],
    data() {
        return {
            ready: false,
            isVideoPaused: true,
            mutedAudioManager: false
        }
    },
    mounted() {
        this.ready = true;
        this.muteAudioManager();
    },
    methods: {
        muteAudioManager() {
            if(!this.$store.getters.getIsMuted) {
                this.mutedAudioManager = true;
                let event = new CustomEvent('muteAudio');
                window.dispatchEvent(event);
            }
        },
        unmuteAudioManager() {
            if(this.mutedAudioManager) {
                let event = new CustomEvent('unmuteAudio');
                window.dispatchEvent(event);
            } 
        },
        closeComponent() {
            this.$emit("closeComponent", this.slug)
        }
    },
    unmounted() {
        this.unmuteAudioManager();
    },
    computed: {
        videoUrl() {
            return app.api.Utils.getMediaPath(this.properties.video.src)
        },
        posterUrl() {
            return app.api.Utils.getMediaPath(this.properties.poster.src)
        }
    }
};
</script>

<style lang="scss">
.fullscreenVideo {
    height: 76%;
    display: flex;
    justify-content: center;
    align-items: center;

    &>video,&>iframe {
        height: 100%;
    }
    
    iframe {
        aspect-ratio: 16 / 9;
    }

    &::after {
        content: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 60px;
        height: 60px;
        pointer-events: none;
        background-image: url(@/assets/img/ui/play.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        z-index: 3;
    }
    
    &::before {
        content: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 120px;
        height: 120px;
        pointer-events: none;
        border-radius: 20px;
        background: var(--dark-grey);
        opacity: 0.3;
        z-index: 3;
    }

    &--videopaused {
        video {
            cursor: pointer;
        }

        &::after,&::before {
            content: '';
        }
    }
}


.fullscreenVideo__close {
    position: absolute;
    left: 100%;
    top: 0;
    height: 60px;
    width: 60px;
    background: var(--petrol);
    background-image: url(@/assets/img/ui/close.svg);
    background-position: center;
    background-repeat: no-repeat;
}
</style>