<script>
export default {
    render: () => null,
    props: ["properties", "pageData", "slug"],
    mounted() {
        window.open(this.composedEmail, '_self');
        this.$emit("closeComponent", this.slug)
    },
    methods: {
        stripHtmlAndAddLineBreaks(htmlString) {
            // Remove all HTML tags except <p> tags from the string
            let strippedString = htmlString.replace(/<(?!(\/)?p\b)[^>]+>/g, '');

            // Add a newline character after every closing </p> tag, excluding the start <p> tag (safari safe version)
            let stringWithLineBreaks = strippedString.replace(/(^|[^>])<\/p>/g, '$1%0D%0A');

            // remove the rest of the <p> start tags
            return stringWithLineBreaks.replace(/<[^>]+>/g, '');
        }
    },
    computed: {
        composedEmail() {
            return `mailto:${this.properties.recipient}${this.emailSubject}${this.emailBody}`
        },
        emailSubject() {
            if (this.properties.subject == "")
                return

            return `?subject=${this.properties.subject}`
        },
        emailBody() {
            if (this.properties.bodyText == "")
                return

            return `&body=${this.stripHtmlAndAddLineBreaks(this.properties.bodyText)}`
        }
    }
};
</script>