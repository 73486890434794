
<template>
    <transition name="fsvarticle" enter>

        <article class="fullscreenVerticalArticle" :class="{ active: ready }" v-if="ready">


            <div class="fullscreenVerticalArticle__scrollarea" ref="scroll_container" @mousewheel="scrollX"
                :class="{ navOpen: navigationOpen }" @DOMMouseScroll="scrollX">

                <!-- hero section -->
                <div class="fullscreenVerticalArticle__herosection"
                    v-if="properties.sections[0].media && properties.useHeroSection">

                    <!-- hero author -->
                    <div class="fullscreenVerticalArticle__author">
                        <img v-if="properties.authorPhoto" :src="getSrc(properties.authorPhoto.src)" />
                        <span v-if="properties.authorName"> {{ properties.authorName }}</span>
                    </div>

                    <!-- hero title -->
                    <h3 class="fullscreenVerticalArticle__title maintitle">{{ properties.sections[0].title }}</h3>

                    <!-- hero media -->
                    <div class="media">
                        <VideoPlayer v-if="isVideo(properties.sections[0].media.src)"
                            :src="getSrc(properties.sections[0].media.src)" class="videoplayer"></VideoPlayer>
                        <img v-else-if="properties.sections[0].media" :src="getSrc(properties.sections[0].media.src)">
                    </div>
                </div>

                <!-- standard columned sections -->
                <div class="fullscreenVerticalArticle__columned">

                    <section v-for="section, i in properties.sections" :key="i"
                        :class="{ 'force-to-next': i < properties.sections.length - 1 && properties.sections[i + 1].newColumn }">

                        <!-- author -->
                        <div v-if="i == 0 && !properties.useHeroSection && (properties.authorPhoto || properties.authorName)"
                            class="fullscreenVerticalArticle__author">
                            <img v-if="properties.authorPhoto" :src="getSrc(properties.authorPhoto.src)" />
                            <span v-if="properties.authorName"> {{ properties.authorName }}</span>
                        </div>

                        <!-- section title -->


                        <h3 class="fullscreenVerticalArticle__title" v-if="!(i == 0 && properties.useHeroSection)"
                            :class="{ maintitle: i == 0 }">
                            {{ section.title }}
                        </h3>

                        <!-- section text -->
                        <div class="fullscreenVerticalArticle__text" v-html="section.text"></div>

                        <!-- section media -->
                        <div class="fullscreenVerticalArticle__media"
                            v-if="section.media && !(i == 0 && properties.useHeroSection)">
                            <VideoPlayer v-if="isVideo(section.media.src)" :src="getSrc(section.media.src)"
                                class="videoplayer"></VideoPlayer>
                            <img v-else-if="section.media" :src="getSrc(section.media.src)">
                        </div>

                    </section>

                </div>

            </div>

            <div class="fullscreenVerticalArticle__menu" v-if="properties.showAnchorNavigation"
                :class="{ hidden: !navigationOpen }">

                <a @click="closeComponent()" class="fullscreenVerticalArticle__close"><span>CLOSE</span><img
                        src="@/assets/img/ui/close_blue.svg"></a>

                <a class="btn-show-nav" @click="navigationOpen = !navigationOpen"> <img
                        src="@/assets/img/ui/arrow_back.png"></a>
                <a class="btn-hide-nav" @click="navigationOpen = !navigationOpen">
                    <span>CLOSE MENU<img src="@/assets/img/ui/arrow_forth.png"></span>
                </a>


                <div class="nav-content">
                    <h4>Content</h4>
                    <div class="content-links">
                        <a v-for="link in scrollTos" :class="{ active: link.active }" @click="scrollTo(link)">
                            <span></span>
                            {{ link.title }}
                        </a>
                    </div>
                </div>
            </div>

            <a v-else @click="closeComponent()" class="fullscreenVerticalArticle__close nomenu"><span>CLOSE</span><img
                    src="@/assets/img/ui/close_blue.svg"></a>

        </article>
    </transition>
</template>


<script>

// components
import VideoPlayer from './VideoPlayer.vue';

// GSAP
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);


export default {
    components: { VideoPlayer },
    props: [
        "properties",
        "pageData",
        "slug"
    ],
    data() {
        return {
            ready: false,
            scrollTos: [],
            navigationOpen: true
        }
    },

    mounted() {


        setTimeout(() => {
            this.ready = true;
        }, 1);


        // hide logo
        document.getElementById("logo").classList.add('hidden');

        setTimeout(() => {
            this.setupSubmenu();
        }, 1);



    },
    methods: {
        closeComponent() {
            this.ready = false;
            this.$emit("closeComponent", this.slug);
            // show logo
            document.getElementById("logo").classList.remove('hidden');
        },
        getSrc(src) {
            return app.api.Utils.getMediaPath(src);
        },
        isVideo(src) {
            return app.api.Utils.getMediaType(src) == 'video';
        },

        setupSubmenu() {


            if (this.properties.showAnchorNavigation) {

                var sectionTitles = document.getElementsByClassName("fullscreenVerticalArticle__title");

                Array.from(sectionTitles).forEach((titleEl, i) => {
                    this.scrollTos.push({
                        title: titleEl.innerHTML,
                        scrollX: titleEl.offsetLeft,
                        active: (i == 0) ? true : false
                    })

                });

                // update menu on scroll
                this.addScollListener();

            } else {
                //if not showing menu set to closed for full scroll-width 
                this.navigationOpen = false;
            }

        },
        scrollX(e) {

            switch (app.api.Utils.getBrowser()) {
                case "Mozilla Firefox":
                    this.$refs['scroll_container'].scrollLeft += 30 * e.detail;
                    break;
                default:
                    this.$refs['scroll_container'].scrollLeft += e.deltaY;
                    break;

            }

        },
        scrollTo(clickedSection) {

            this.scrollTos.forEach((section, i) => {
                section.active = (clickedSection == section) ? true : false;
            });

            var scrollContainer = this.$refs['scroll_container'];

            // stop scroll listener
            this.removeScrollListener();

            // animate
            var maxScroll = scrollContainer.scrollWidth - scrollContainer.clientWidth;
            var scrollToBounded = Math.min(maxScroll, clickedSection.scrollX);
            gsap.to(scrollContainer, {
                duration: 0.5, ease: "expo.InOut", scrollTo: {
                    y: 0, x: scrollToBounded
                }, onComplete: this.addScollListener
            });


        },

        addScollListener() {
            this.$refs['scroll_container'].addEventListener('scroll', this.updateActiveMenuLink);
        },

        removeScrollListener() {
            this.$refs['scroll_container'].removeEventListener('scroll', this.updateActiveMenuLink);
        },

        updateActiveMenuLink() {

            const activeOnPixelsBefore = 300;
            var currentX = this.$refs['scroll_container'].scrollLeft + activeOnPixelsBefore;

            this.scrollTos.forEach((section, i) => {
                var nextSectionX = (i < this.scrollTos.length - 1) ? this.scrollTos[i + 1].scrollX : 10000000;

                if (section.scrollX <= currentX && currentX < nextSectionX) {
                    section.active = true;
                } else {
                    section.active = false;
                }

                //console.log(section.scrollX + "<" + currentX + "<" + nextSectionX);

            });


        }
    }
};
</script>

<style lang="scss" scoped>
* {
    --column-width: 320px;
    --column-gap: 140px;
}

.fullscreenVerticalArticle {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 1880px;
    bottom: 120px;
    background-color: #fff;
    overflow: hidden;


    &__close {
        position: absolute;
        right: 460px;
        top: 40px;
        display: flex;
        align-items: center;
        color: var(--main-color-darker);
        cursor: pointer;

        img {
            margin-left: 10px;
        }

        &.nomenu {
            right: 40px;
        }
    }




    &__scrollarea {
        position: absolute;
        right: 140px;
        left: 140px;
        top: 110px;
        bottom: 33px;
        text-align: left;
        padding-right: 150px;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;

        &.navOpen {
            right: 500px;
        }

    }

    &__columned {
        height: 100%;
        width: fit-content;
        column-width: var(--column-width);
        width: var(--column-width);
        column-gap: var(--column-gap);
        column-count: auto;
        column-fill: auto;
        height: calc(100% - 50px); // 50px margin

        section {

            margin-bottom: 60px;

            &.force-to-next {
                margin-bottom: 1000px
            }
        }
    }

    &__author {
        display: flex;
        align-items: flex-end;
        gap: 20px;
        margin-bottom: 20px;

        img {
            width: 64px;
            height: 64px;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    &__media {

        margin-top: 40px;

        img {
            width: 100%;
        }
    }

    &__herosection {

        width: calc(2 * var(--column-width) + var(--column-gap));
        margin-right: var(--column-gap);
        padding-top: 50px;
        padding-bottom: 60px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        .fullscreenVerticalArticle__author {
            margin-bottom: 40px;
        }

        .media {

            img,
            video {
                width: 100%;
                // max-height: 400px;
                aspect-ratio: 16/9;
                object-fit: cover;
            }
        }
    }


    &__title {
        font-size: 32px;
        font-weight: 800;
        margin-bottom: 30px;
        color: var(--main-color);

        &.maintitle {
            font-size: 50px;
            margin-bottom: 60px;
        }

    }

    &__text {
        color: var(--coptext);

    }

    &__menu {
        position: absolute;
        width: 420px;
        right: 0;
        height: 100%;
        background-color: var(--main-color-lighter);
        z-index: 10;

        padding: 40px;
        text-align: left;
        color: #fff;
        transition: transform 0.3s;

        .btn-hide-nav {
            position: absolute;
            opacity: 1;
            cursor: pointer;

            img {
                margin-left: 10px;
            }
        }

        .btn-show-nav {
            opacity: 0;
            cursor: pointer;
            position: absolute;
            left: 0px;
            top: 0;
            padding-top: 40px;
            width: 60px;
            text-align: center;
            height: 100%;
            pointer-events: none;
        }


        .nav-content {
            margin-top: 100px;

            h4 {
                padding-bottom: 20px;
                margin-bottom: 20px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            }

            .content-links {

                a {
                    display: flex;
                    line-height: 50px;
                    cursor: pointer;
                    align-items: center;

                    span {
                        width: 18px;
                        height: 2px;
                        margin-right: 12px;
                        background-color: #fff;
                        opacity: 0.1;
                        transition: opacity 0.5s;
                    }

                    &.active {
                        font-weight: bold;

                        span {
                            opacity: 1;
                        }

                    }


                }

            }
        }

        &.hidden {
            transform: translateX(85%);

            .btn-hide-nav,
            .nav-content {
                opacity: 0;
            }

            .btn-show-nav {
                opacity: 1;
                pointer-events: all;
            }
        }





    }



}

.fsvarticle-enter-active,
.fsvarticle-leave-active {
    transition: all .5s cubic-bezier(0.770, 0.000, 0.175, 1.000);
}

.fsvarticle-enter-from,
.fsvarticle-leave-to {
    opacity: 0;
    transform: translateY(-50%);
}
</style>