<template>
  <article class="Presenter" :style="[presenterPosition, scalePresenter]">
    <!-- Intro video -->
    <video id="intro" ref="intro" v-if="hasIntroVideo" v-show="introPlaying" autoplay playsinline muted>
        <source :src="getMediaPath(properties.intro.mov.src)" type='video/mp4; codecs="hvc1"'> 
        <source :src="getMediaPath(properties.intro.webm.src)" type="video/webm">
    </video>

    <!-- Idle video -->
    <video id="idle" ref="idle" v-show="!mainPlaying && !introPlaying && hasIdleVideo" :autoplay="!hasIntroVideo" playsinline muted loop>
        <source :src="getMediaPath(properties.idle.mov.src)" type='video/mp4; codecs="hvc1"'> 
        <source :src="getMediaPath(properties.idle.webm.src)" type="video/webm">
    </video>

     <!-- Main video -->
    <video id="main" ref="main" v-show="mainPlaying && !introPlaying && hasMainVideo" playsinline>
        <source :src="getMediaPath(properties.main.mov.src)" type='video/mp4; codecs="hvc1"'> 
        <source :src="getMediaPath(properties.main.webm.src)" type="video/webm">
    </video>

    <!-- Hotspot button for starting the video -->
    <transition name="fadeIn">
        <button class="Hotspot defaultHotspot btnfx" :style="hotspotPosition" v-show="!mainPlaying && !introPlaying" @click="play">
            <div class="defaultHotspot__button btnfx__clicklight btnfx__hoverhalo"></div>
            <div class="defaultHotspot__label" v-if="properties.hotspotTitle" v-html="properties.hotspotTitle"></div>
        </button>
    </transition>

    <!-- Hotspot button for skipping the video -->
    <transition name="fadeIn">
        <button class="Hotspot defaultHotspot btnfx" :style="hotspotPosition" v-show="mainPlaying && !introPlaying" @click="skip">
            <div class="defaultHotspot__button defaultHotspot__button--skip btnfx__clicklight btnfx__hoverhalo" aria-hidden="true"></div>
        </button>
    </transition>
  </article>
</template>

<script>
export default {
    props: ["properties", "pageData"],
    data() {
        return {
            videos: {},

            introPlaying: false,
            mainPlaying: false
        }
    },
    created() {
        // Play intro video if it has been set in Umbraco.
        this.introPlaying = this.hasIntroVideo ? true : false;
    },
    mounted() {
        this.videos = {
            intro: this.$refs.intro,
            idle: this.$refs.idle,
            main: this.$refs.main
        }

        // Event handlers for video element states
        this.events = {
            // Handler for intro video ended
            "introEnded": () => {
                this.videos.idle.currentTime = 0;
                this.videos.idle.play();
                this.introPlaying = false;
            },

            // Handler for main video ended
            "ended": () => {
                this.mainPlaying = false;
                app.api.Tracking.event("Presenter", this.properties.hotspotTitle, "Watched")
            },

            // Set size of video to the wrapper when the idle video has been loaded.
            "loadedmetadata": () => {
                this.$el.style.width = this.videos.idle.videoWidth + "px";
                this.$el.style.height = this.videos.idle.videoHeight + "px";
            }
        };

        // Add above event listeners
        if (this.videos.intro) {
            this.videos.intro.addEventListener("ended", this.events.introEnded)
        }
        if (this.videos.idle) {
            this.videos.idle.addEventListener("loadedmetadata", this.events.loadedmetadata)
        }
        if (this.videos.main) {
            this.videos.main.addEventListener("ended", this.events.ended)
        }

        // Event listener to stop Presenter from playing if another Presenter starts playing in the same scene.
        window.addEventListener("presenterPlaying", e => {
            if(e.detail.presenterPlaying !== this.properties.hotspotTitle && !this.videos.main.paused) {
                this.skip();
            }
        });

        this.preloadMainVideo();
    },
    beforeUnmount() {
            // Remove event listeners and pause videos before unmounting the component
            if (this.videos.intro) {
                this.videos.intro.removeEventListener("ended", this.events.introEnded)
            }

            this.videos.idle.removeEventListener("loadedmetadata", this.events.loadedmetadata)
            this.videos.main.removeEventListener("ended", this.events.ended)

            Object.values(this.videos).forEach(video => {
                if(video)
                    video.pause();
            });
    },
    methods: {
        play() {
            let event = new CustomEvent('presenterPlaying', { detail: { presenterPlaying: this.properties.hotspotTitle } });
            window.dispatchEvent(event);
            this.videos.main.play();
            this.videos.idle.currentTime = 0;
            this.mainPlaying = true;
            app.api.Tracking.event("Presenter", this.properties.hotspotTitle, "Play")
        },
        skip() {
            this.videos.main.pause();
            this.videos.main.currentTime = 0;
            this.mainPlaying = false;
            app.api.Tracking.event("Presenter", this.properties.hotspotTitle, "Skip")
        },

        async preloadMainVideo() { 
            if(!this.hasMainVideo)
                return


            var src = "";
            
            if(app.api.Utils.getBrowser == "Safari") {
                src = this.properties.main.mov.src        
            } else {
                src = this.properties.main.webm.src
            }

            await app.api.Utils.preloadVideo(src);
        },
        getMediaPath(src) {
            return app.api.Utils.getMediaPath(src)
        }
    },
    computed: {
        scalePresenter() {
            return {
                transform: `scale(${this.properties.presenterSize})`
            }
        },
        presenterPosition() {
            return {
                left: this.properties.presenterPosition.xPosition + "px",
                top: this.properties.presenterPosition.yPosition + "px"
            }
        },
        hotspotPosition() {
            return {
                left: this.properties.hotspotPosition.xPosition + "px",
                top: this.properties.hotspotPosition.yPosition + "px"
            }
        },
        hasIntroVideo() {
            return !(this.properties.intro.mov === null || this.properties.intro.webm === null)
        },
        hasIdleVideo() {
            return !(this.properties.idle.mov === null || this.properties.idle.webm === null)
        },
        hasMainVideo() {
            return !(this.properties.main.mov === null || this.properties.main.webm === null)
        }
    }
};
</script>

<style lang="scss" scoped>
.Presenter {
    position: absolute;

    video {
        position: absolute;
        top:0;
        left: 0;
    }

    .Hotspot {
        z-index: 2;

        .Hotspot {
            background-size: 24px;
            background-image: url(@/assets/img/ui/play.svg);
            padding-left: 2px;
            transform: rotate(0deg) !important;

            &--skip {
                background-image: url(@/assets/img/ui/close.svg);
                padding-left: 0;
            }
        }
    }
}
  </style>