import config from "@/config";

// temp variable to hold promise data
let data = false;

// fetch method to get manifest data
export default function MakeLoader({DataDecorator = false}) {
    return Object.freeze({
        fetchData,
        get data() {
            return data;
        }
    })

    async function fetchData() {
        var path = config.manifest.replace(/@lang/g, getLanguage());
        var globalFetchOptions = {
            mode: "cors",
            headers: {
                "Accept-language": 'en'
            }
        }
        var localFetchOptions = {
            cache: "no-store"
        }

        var fetchOptions = Object.assign({}, localFetchOptions, globalFetchOptions);
        return new Promise((resolve, reject) => {
            fetch(path, fetchOptions).then(o => o.json())
                .then(o => {
                    data = DataDecorator ? DataDecorator(o) : o;
                    console.log("Fetched manifest successfully")
                    resolve();
                })
                .catch(e => {
                    console.log(e)
                    reject(e)
                })
        })
    }

    function getLanguage() {        
        if(config.includeLanguageCodeInUrl){
            app.api.LocalDataStore.set("lang", getLanguageCodeFromURL())
        }

        let language = app.api.LocalDataStore.get("lang")
        if(!language) {
            language = config.defaultLanguage;
        }

        return language
    }

    function getLanguageCodeFromURL() {
        const pathArray = window.location.hash.split('/');
        if (pathArray.length > 1 && pathArray[1] !== "") {
            const potentialLangCode = pathArray[1];
            // Validate the potentialLangCode if necessary, e.g., check if it matches a known language code pattern
            return potentialLangCode;
        }

        // Return the default/saved languageCode if non is present in the URL
        return app.api.LocalDataStore.get("lang");
    }
}