<template>
    <component
        v-for="(component, i) in components"
        :is="getComponentClass(component)"
        :key="createComponentKey(i, component)"
        :properties="getProperties(component)"
         :children="component.children"
        :pageData="pageData"
        :activeHotspots="activeHotspots"
        :slug="component.slug"
        :type="component.type"
        @closeComponent="closeComponent"
        @closeAllComponents="closeAllComponents"
        :style="{'z-index': i + 1}">
    </component>
</template>

<script>
import ComponentRepo from "../../services/ComponentRepo";

export default {
  props: ["components", "pageData", "activeHotspots"],
  methods: {
    getComponentClass(component) {
      return ComponentRepo.has(component.type)
        ? component.type
        : "NullComponent";
    },
    createComponentKey(index, component) {
        let id = index;

        if(component.id)
            id = component.id;

        return `component-${index}${id}`;
    },
    getProperties(component) {
        return {
            id: component.id,
            ...component.properties
        }
    },
    closeComponent(slug) {
        app.api.PageManager.closeContentComponent(slug)
    },
    closeAllComponents() {
        app.api.PageManager.closeAllContentComponents();
    }
  },
};
</script>

<style lang="scss">

/* default Content Component Transition (background components have no transition by default)*/
.toggleContentComponent-enter-active {
    transition-property: transform, opacity;
    transition-duration: 0.4s;
    transition-timing-function: var(--tk-easeinout);
}

.toggleContentComponent-leave-active {
    transition-property: transform, opacity;
    transition-duration: 0.2s;
    transition-timing-function: var(--tk-easeinout);
}

.toggleContentComponent-enter-from {
    transform: translateY(40px);
    opacity: 0;
}

.toggleContentComponent-leave-to {
    opacity: 0;
}
</style>