<template>
    <div class="PageBackground" ref="pageBackground" :style="{ width: getMediaWidth + 'px' }"
        :class="getBackgroundClasses" :data-background-width="getMediaWidth">
        <video :src="backgroundVideo" ref="bgVideo" v-if="hasVideo" @loadedmetadata="measureVideoWidth" muted autoplay
            loop playsinline webkit-playsinline type="video/mp4" @contextmenu.prevent=""
            disablepictureinpicture></video>
        <img v-if="hasImage" :src="backgroundImage" :alt="background.image.name" @load="backgroundLoaded">
    </div>
</template>

<script>
export default {
    props: ["background", "hasContentComponentOpen", "scrollingBackground"],
    data() {
        return {
            videoWidth: 0,
            staticBgTimer: null
        }
    },

    methods: {
        async measureVideoWidth() {
            const videoElement = this.$refs.bgVideo;
            if (videoElement) {
                this.videoWidth = videoElement.videoWidth;
            }

            await this.$nextTick
            this.backgroundLoaded();
        },
        backgroundLoaded() {
            window.dispatchEvent(new Event('backgroundLoaded'));
        },
        setStaticBackgroundImage(imagePath) {
            // console.log(this.staticBgTimer);
            clearTimeout(this.staticBgTimer);
            // add a copy of the background image behind .page (transition) to make overlap fading possible
            var staticBackgroundContainer = document.querySelector(".PageStaticBackground");
            if (staticBackgroundContainer) {
                this.staticBgTimer = setTimeout(function () {
                    staticBackgroundContainer.innerHTML = "<img src='" + imagePath + "'>";
                }, 500);
            }
        }
    },
    computed: {
        hasVideo() {
            return Object.keys(this.background)[0] === 'video'
        },
        getMediaWidth() {
            if (this.hasImage) {
                this.$store.commit('setBackgroundWidth', this.background.image.width)
                return this.background.image.width;
            } else if (this.hasVideo && this.videoWidth > 0) {
                this.$store.commit('setBackgroundWidth', this.videoWidth)
                return this.videoWidth;
            }
        },
        backgroundVideo() {
            if (this.hasVideo) {
                return app.api.Utils.getMediaPath(this.background.video.src)
            } else {
                return null;
            }
        },
        hasImage() {
            return Object.keys(this.background)[0] === 'image'
        },
        backgroundImage() {
            if (this.hasImage) {

                var imagePath = app.api.Utils.getMediaPath(this.background.image.src);

                this.setStaticBackgroundImage(imagePath);

                return imagePath;

            } else {
                return null;
            }
        },
        getBackgroundClasses() {
            return {
                'PageBackground--scrollable': this.scrollingBackground,
                'PageBackground--scrolling': this.$store.getters.isBackgroundDragged
            }
        }
    },

};
</script>

<style lang="scss" scoped>
.PageBackground {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: -1;

    &>* {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &--scrollable {
        cursor: grab;
    }

    &--scrolling {
        cursor: grabbing;
    }
}
</style>