<template>
    <transition name="HotspotEntrance">
        <button class="Hotspot dropdownHotspot" v-show="ready" :class="[getHotspotMutatorClasses, getHotspotTypeClass]" :style="[getPosition]">
            <div class="Hotspot__button" :style="[getAlternateHotspotIcon]" @click="toggleDropdown" :class="{'open': activeDropdown === hotspot.id}"></div>
            <span v-html="getHotspotTitle" @click="toggleDropdown"></span>
            <transition name="dropdownHotspot">
                <div v-show="activeDropdown === hotspot.id" class="dropdownHotspot__list">
                    <router-link v-for="hotspot in hotspotList" :to="hotspot.route" :key="hotspot.id"  @click="hotspotPressed">
                        {{hotspot.data.title}}
                    </router-link>
                </div>
            </transition>
        </button>
    </transition>
</template>

<script>

export default {
    props: ['hotspot', 'activeDropdown'],
    data() {
        return {
            ready: false,
            hotspotOpen: false,
            hotspotList: []
        }
    },
    created() {
        this.prepareHotspotList();
    },
    mounted() {
        this.renderHotspotCascade();
    },
    methods: {
        prepareHotspotList() {
            this.hotspotList = this.hotspot.children.map((hotspot, i) => {
                hotspot.route = this.getRoute(hotspot);
                return hotspot;
            })
        },
        getRoute(hotspot) {
            let hotspotReferenceID = Object.values(hotspot.data.type)[0];

            if(Object.keys(hotspot.data.type)[0] == 'scene') {
                return app.api.PageManager.getHotspotSceneRoute(hotspot.id);
            } else if (Object.keys(hotspot.data.type)[0] == 'content') {
                return app.api.PageManager.getRouteFromIDs(null, hotspotReferenceID)
            } else {
                return '/'
            }
        },
        renderHotspotCascade() {
            let delay = 0;
            delay = (this.hotspot.index * 200) + 400;

            setTimeout(() => {
                this.ready = true;
            }, delay);
        },
        hotspotPressed() {
            this.$emit('hotspotPressed', this.hotspot)
        },
        toggleDropdown() {
            this.activeDropdown === this.hotspot.id ? this.$emit('currentDropdown', null) : this.$emit('currentDropdown', this.hotspot.id);
        },
    },
    computed: {
        getPosition() {
            return {
                left: this.hotspot.data.positionData.xPosition + 'px',
                top: this.hotspot.data.positionData.yPosition + 'px'
            }
        },
        getHotspotTitle() {
            return this.hotspot.data.alternateTitle && this.hotspot.data.alternateTitle !== '' ? this.hotspot.data.alternateTitle : this.hotspot.data.title;
        },
        getHotspotTypeClass() {
            let hotspotPrefix = "Hotspot--"            

            switch(this.hotspot.data.hotspotType) {
                case "No Label":
                    return `${hotspotPrefix}NoLabel` 

                // more types can be added here through the backend hotspotType data value.

                default: 
                    return
            }
        },
        getHotspotMutatorClasses() {
            return {
                'Hotspot--flipped': this.hotspot.data.flipped,
                'Hotspot--customicon': this.hotspot.data.alternateHotspotIcon !== null
            }
        },
        getAlternateHotspotIcon() {
            if(this.hotspot.data.alternateHotspotIcon !== null) {
                return {
                    'background-image': `url(${app.api.Utils.getMediaPath(this.hotspot.data.alternateHotspotIcon.src)})`
                };
            }
        }
    }
}
</script>


<style lang="scss">
.dropdownHotspot {
    .Hotspot__button{
        transition: rotate 0.2s ease;
        &.open { rotate: 45deg; }
    }

    &__list {
        position: absolute;
        top: calc(100% - 3px);
        width: calc(100% - 32px);
        box-shadow: var(--shadow-medium);
        margin: 0 0 0 32px;

        a {
            display: flex;
            align-items: center;
            font-weight: 500;
            color: var(--light-grey);
            font-size: 15px;
            min-height: 38px;
            padding: 8px 15px 8px 26px;
            text-align: left;
            background: var(--main-color);
            border-bottom: 1px solid var(--light-grey);

            &:last-child { border-bottom: none; }
            &:hover { background-color: var(--secondary-color); }
        }
    }


    &.Hotspot--flipped {
        .dropdownHotspot__list {
            margin: 0 32px 0 0;
            a {
                padding: 8px 16px 8px 20px;
            }
        }
    }
}

/* dropdown animation */
.dropdownHotspot-enter-active,
.dropdownHotspot-leave-active {
    transition: all 0.4s var(--tk-easeinout);
}
.dropdownHotspot-enter-from {
    transform: translateY(-38px);
    opacity: 0;
}
.dropdownHotspot-leave-to {
    transform: translateY(-38px);
    opacity: 0;
}
</style>