<template>
    <nav id="UiLayer">
        <Logo :class="{ 'visible': !hasFullscreenContentComponent }"></Logo>
        <Navigation :class="{ 'visible': !hasFullscreenContentComponent }"></Navigation>
    </nav>
</template>
<script>
import Logo from "../ui/Logo.vue";
import Navigation from "../ui/Navigation.vue";


export default {
    components: {
        // Ui Components
        Logo,
        Navigation,
    },
    data() {
        return {
        };
    },
    methods: {},
    computed: {
        onHomePage() {
            this.$route;
            return app.api.PageManager.onHomePage();
        },

        hasFullscreenContentComponent() {
            this.$route
            var hasFullscreenComponent = false;
            if (!app.api.PageManager.getIsContentComponentOpen())
                return hasFullscreenComponent

            var fullscreenComponents = ["articleFull", "fullscreenVideo"];
            var openComponents = app.api.PageManager.getOpenContentComponents()
            var i = 0;

            while (i < openComponents.length) {
                if (fullscreenComponents.includes(openComponents[i].type)) {
                    hasFullscreenComponent = true
                }
                i++
            }

            return hasFullscreenComponent
        },
        // getMaximizedSizeAndOffset() {
        //     return this.$store.getters.getMaximizedSizeAndOffsetValue;
        // },
    }
};
</script>

<style lang="scss">
#UiLayer {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    button {
        position: absolute;
        left: 50px;
        bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        height: 50px;
        width: 50px;
        border: 2px solid var(--light-grey);
        background: var(--main-color);
        box-shadow: var(--shadow-medium);
        transition-property: opacity, transform;
        transition-duration: 0.1s;
        transition-timing-function: ease;

        img {
            width: 32px;
        }

        &:hover {
            transform: scale(1.05);
        }
    }

    *>.visible {
        pointer-events: all;
    }
}
</style>