<template>
<div class="PageStaticBackground"></div>
</template>

<style lang="scss" scoped>
.PageStaticBackground {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: -1;
}
</style>